import { Slider, SliderSettings } from 'presentation/components'
import { MouseEventHandler, ReactNode } from 'react'
import * as S from './OfficialStores.styles'

type CustomArrowProps = {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  className?: string
}

export const CustomPreviousArrow = (props: CustomArrowProps) => (
  <S.PrevButton
    onClick={props.onClick}
    data-testid="previous-arrow"
    isDisabled={!!props?.className?.includes('slick-disabled')}
  />
)

export const CustomNextArrow = (props: CustomArrowProps) => (
  <S.NextButton
    onClick={props.onClick}
    data-testid="next-arrow"
    isDisabled={!!props?.className?.includes('slick-disabled')}
  />
)

export function beforeChange(previousIndex, newIndex) {
  const slideItems = document.querySelectorAll('.slick-dots li')
  if (Number.isInteger(newIndex)) {
    slideItems[slideItems.length - 1].className = ''
    return
  }

  slideItems[slideItems.length - 1].className = 'slick-active'
}

const TOTAL_SLIDES_TO_SHOW = 6

type OfficialStoresSliderProps = {
  children: ReactNode
  slideLength?: number
}

export function OfficialStoresSlider({
  children,
  slideLength = 0,
}: OfficialStoresSliderProps) {
  const canEnableInfinite = slideLength >= TOTAL_SLIDES_TO_SHOW

  const settings: SliderSettings = {
    slidesToShow: TOTAL_SLIDES_TO_SHOW,
    autoplay: true,
    slidesToScroll: TOTAL_SLIDES_TO_SHOW,
    dots: true,
    autoplaySpeed: 8000,
    infinite: canEnableInfinite,
    arrows: true,
    responsive: [
      {
        breakpoint: 570,
        settings: {
          arrows: false,
          beforeChange,
          infinite: slideLength >= 4,
          slidesToShow: 4.3,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 320,
        settings: {
          arrows: false,
          beforeChange,
          infinite: slideLength >= 3,
          slidesToShow: 3.2,
          slidesToScroll: 3
        }
      }
    ],
    prevArrow: <CustomPreviousArrow />,
    nextArrow: <CustomNextArrow />,
    customPaging: () => <div className="ft-slick__dots--custom"></div>
  }

  return (
    <S.SliderWrapper
      data-testid="slider-testid"
      key={'store-slider'}
      canEnableInfinite={canEnableInfinite}
    >
      <Slider settings={settings}>{children}</Slider>
    </S.SliderWrapper>
  )
}
