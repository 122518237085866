import { useQuery } from '@tanstack/react-query'
import { searchService } from 'application'
import { Store } from 'domain/OfficialStore'
import { Box } from 'presentation/components'
import { useMobile } from 'presentation/hooks'
import { useEffect, useMemo, useState } from 'react'
import { OfficialStoreCard } from '../../Cards'
import { useSearchSectionController } from '../../SearchSection/SearchSectionController'
import * as S from './OfficialStores.styles'
import { useOfficialStoresController } from './OfficialStoresController'
import { OfficialStoresSlider } from './OfficialStoresSlider'
import { reorderStoresByLastSearch } from './reorder-stores-by-last-search'

export function OfficialStores({ position = '' }) {
  const [reorderedStores, setReorderedStores] = useState<Store[]>([])
  const isMobile = useMobile()
  const { data: lastSearches } = useQuery({
    queryKey: ['last_search'],
    queryFn: () => {
      return searchService.getLastSearches()
    }
  })

  const { searchType } = useSearchSectionController()
  const { customOfficialStores } = useOfficialStoresController()

  const { position: componentPosition, stores } = useMemo(
    () => customOfficialStores,
    [customOfficialStores, searchType]
  )


  const type = searchType
  const storesByType = stores.filter((store) => {
    const isSameType = store.type?.toLowerCase() === type
    const isActive = store.status?.toLowerCase() === 'active' || store.status?.toLowerCase() === 'activebyschedule'
    return isSameType && isActive
  })

  useEffect(() => {
    const stores = reorderStoresByLastSearch(
      storesByType,
      searchType,
      lastSearches
    ) as unknown as Store[]

    setReorderedStores(stores)
  }, [stores, searchType])

  const canShowSlider = isMobile
    ? reorderedStores.length > 4
    : reorderedStores.length >= 7

  if (!reorderedStores.length || componentPosition !== position) return null

  return (
    <S.Container data-testid="official-stores-container">
      <Box mt={4} mb={6}>
        <S.Typography variant="h2" color="textSecondary" mb={2}>
          Lojas Oficiais
        </S.Typography>

        {canShowSlider ? (
          <OfficialStoresSlider
            slideLength={reorderedStores.length}
          >
            {reorderedStores.map((officialStore, index) => (
              <div
                data-testid="slick-item"
                className="slick-item"
                key={officialStore.id}
              >
                <OfficialStoreCard
                  title={officialStore.title}
                  urlImage={`${officialStore.imageUrl}?s=fill&w=180&h=180&q=70`}
                  href={officialStore.url}
                  dataQA={`officialStore_card_${index + 1}`}
                />
              </div>
            ))}
          </OfficialStoresSlider>
        ) : (
          <S.WithoutSliderWrapper>
            {reorderedStores.map((officialStore, index) => (
              <div
                data-testid="slick-item"
                className="slick-item"
                key={officialStore.title}
              >
                <OfficialStoreCard
                  title={officialStore.title}
                  urlImage={`${officialStore.imageUrl}?s=fill&w=180&h=180&q=70`}
                  href={officialStore.url}
                  dataQA={`officialStore_card_${index + 1}`}
                />
              </div>
            ))}
          </S.WithoutSliderWrapper>
        )}
      </Box>
    </S.Container>
  )
}
