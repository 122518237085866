import { BaseFindOneRepository } from 'domain/BaseRepository'
import { HttpGetClient } from 'domain/HttpClient'
import { OfficialStore, Store } from '../Model/OfficialStore'

export type StoreDto = {
  brandId: string
  brandName: string
  urlLogo: string
  urlOfficialStore: string
  inactivationDate: string
  activationDate: string
  status: string
  vehicleType: string
  isDefaultLogo: boolean
}

export class RemoteOfficialStoreRepository
  implements BaseFindOneRepository<OfficialStore> {
  constructor(private httpGetClient: HttpGetClient) { }

  /**
   * Get all available categories
   *
   * @returns
   */
  async findOne(): Promise<OfficialStore> {
    const { result } = await this.httpGetClient.get<{ result: StoreDto[] }>(
      `/v2/loja-oficial`
    )

    const stores = result?.map(
      (store) =>
        new Store({
          id: store.brandId,
          title: store.brandName,
          imageUrl: store.urlLogo,
          url: store.urlOfficialStore,
          status: store.status,
          type: store.vehicleType
        })
    )

    return { position: 'top', stores }
  }
}
